<template>
  <div>
    <!-- TODO: Turn this into EventDescriptorCard instead -->
    <DescriptorCard
      :fixed_params="fixed_params"
      :_index_params="index_params"
      :stateIdentifier="cache_key"
      :small="small"
      :allow_relationships_filter="allow_relationships_filter"
      :allow_properties_filter="allow_properties_filter"
      :allow_json_filter="allow_json_filter"
      :allow_predefined_filter="allow_predefined_filter"
      :allow_select_fields="allow_select_fields"
      class_name="Event"
      title="Events"
    ></DescriptorCard>
  </div>
</template>

<script>
export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {
    account: {
      // required: true,
    },
    user: {
      // required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    allow_relationships_filter: {
      type: Boolean,
      default: true,
    },
    allow_properties_filter: {
      type: Boolean,
      default: true,
    },
    allow_json_filter: {
      type: Boolean,
      default: false,
    },
    allow_predefined_filter: {
      type: Boolean,
      default: true,
    },
    allow_select_fields: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    fixed_params() {
      let fixed_params = {};

      if (this.account) {
        fixed_params = _.merge(fixed_params, this.$d.relationFixedParams('Event', 'ForAccount', [this.account]));
      }
      if (this.$auth.user) {
        fixed_params = _.merge(fixed_params, this.$d.relationFixedParams('Event', 'ByUser', [this.$auth.user]));
      }

      return fixed_params;
    },
    index_params() {
      let index_params = {};

      if (this.account) {
        index_params = this.$d.mergeFilterParams(index_params, this.$d.relationIndexParams('Event', 'ForAccount', this.account['id']));
      }
      if (this.user) {
        index_params = this.$d.mergeFilterParams(index_params, this.$d.relationIndexParams('Event', 'ByUser', this.user['id']));
      }
      return index_params;
    },
    cache_key() {
      let cache_key = '_billing_account';
      if (this.account) {
        cache_key += '_' + this.account['id'];
      }
      return cache_key;
    },
  },
  methods: {},
  created() {},
};
</script>
